import React from 'react';

import { withPrefix } from 'gatsby';
import { IntlProvider } from 'react-intl';

import { I18nContextProvider } from './i18n-context';

const withI18nProvider = (i18n) => children => {
  return (
    <IntlProvider locale={i18n.language} defaultLocale={i18n.defaultLanguage} messages={i18n.messages}>
      <I18nContextProvider value={i18n}>{children}</I18nContextProvider>
    </IntlProvider>
  );
};

export default ({ element, props }, pluginOptions) => {
  if (!props) return;

  const { pageContext, location } = props;
  const { defaultLanguage } = pluginOptions;
  const { i18n } = pageContext;
  const { language, languages, redirect, routed, paths } = i18n;

  if (typeof window !== 'undefined') {
    window.__gatsbyI18n = i18n;
  }

  const isRedirect = redirect && !routed && typeof window !== 'undefined';

  if (isRedirect && window?.location.href.indexOf('/404') < 0) {
    const { search } = location;

    if (typeof window !== 'undefined') {
      let detected = window?.location.href.includes('/en/') > 0 ? 'en' : 'fr';
      const queryParams = search || '';
      const slug = paths?.find(p => p.label === detected).link || '';
      let newUrl = withPrefix(`/${detected}${slug}${queryParams}`);
      window.localStorage.setItem('gatsby-i18n-language', detected);

      (slug.includes('/404') || slug.includes('-404-')) ? newUrl = withPrefix(`/${detected}`)/*withPrefix(`/${detected}/404${queryParams}`)*/ : '';
      if (window.location.href.indexOf('localhost') < 0) {
        window.location.replace(newUrl);
      }
    }
  }

  return withI18nProvider(i18n)(element);
};
